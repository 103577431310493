import { useTranslation } from "react-i18next";

import { useConfirmAccountMutation } from "../../app/api/public/account";
import { Account } from "../../app/models/Account";
import { AccountForm } from "./AccountForm";
import { SignUpState, SignUpStateType } from "./SignUpState";

type SignUpConfirmAccountFormProps = {
  account: Account;
  onChange: React.Dispatch<React.SetStateAction<SignUpState>>;
};

export const SignUpConfirmAccountForm = (
  props: SignUpConfirmAccountFormProps,
) => {
  const { account, onChange } = props;

  const [confirmAccount] = useConfirmAccountMutation();

  const { t } = useTranslation();
  const { successMessage } = t(
    "features.account.SignUpConfirmAccountForm",
  ) as unknown as { successMessage: string };

  return (
    <AccountForm
      value={account}
      onSubmit={async (values) => {
        try {
          await confirmAccount(values).unwrap();

          onChange({
            step: SignUpStateType.RESULT,
            message: successMessage,
            session: { email: account.email },
          });
        } catch {}
      }}
    />
  );
};
